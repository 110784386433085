import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Loading from '../components/templates/misc/loading/Loading';

const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));

const Navigator = () => {
  const loadingTemplate = () => <Loading />;

  return (
    <React.Suspense fallback={loadingTemplate()}>
      <BrowserRouter>
        <DefaultLayout />
      </BrowserRouter>
    </React.Suspense>
  );
};

export default Navigator;
