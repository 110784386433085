import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import './assets/styles/tailwind.scss';
import { App }  from './App'

if (process.env.NODE_ENV === 'production') {
  console.log = (f) => f;
  console.error = (f) => f;
}

const rootElement = document.getElementById('root');
render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement,
);