import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUserInformation = createAsyncThunk('general/getuserinformation', async () => {
  try {
    console.log('geral');
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export const setAlertAction = (state, action) => {
  console.log(state);
  return {
    ...state,
    alert: {
      ...action.payload,
      showAlert: true,
    },
  };
};
