import { configureStore } from '@reduxjs/toolkit';
import { generalSlices } from './reducers/generalSlices';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    general: generalSlices.reducer,
  },
});

// export type AppDispatch = typeof store.dispatch;
