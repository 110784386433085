const Loading = () => {
  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0" style={{ background: '#1A1446E6' }}></div>
      <div className="h-full flex justify-center items-center">
        <p>cargando</p>
      </div>
    </div>
  );
};

export default Loading;
