import { createSlice } from '@reduxjs/toolkit';
import { getUserInformation, setAlertAction } from '../actions/generalActions';

const initialState = {
  data: {
    intermediary: {},
  },
  isLoading: false,
  error: null,
  alert: {
    showAlert: false,
    title: '',
    text: '',
    type: '',
  },
};

export const generalSlices = createSlice({
  name: 'general',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserInformation.fulfilled, (state, { payload }) => {
      state.data = payload;
      state.isLoading = false;
    });
    builder.addCase(getUserInformation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserInformation.rejected, (state) => {
      state.isLoading = false;
    });
  },
  reducers: {
    onRestartStateGeneral: (state) => {
      state.data = null;
      state.isLoading = false;
      state.error = null;
    },
    setAlert: (state, action) => {
      console.log(state);
      return {
        ...state,
        alert: {
          ...action.payload,
          showAlert: true,
        },
      };
    },
    closeAlert: (state) => {
      state.alert.showAlert = false;
      state.alert.text = '';
      state.alert.title = '';
      state.alert.type = '';
    },
  },
});

export const { onRestartStateGeneral, setAlert, closeAlert } = generalSlices.actions;
